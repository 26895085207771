<template>
  <div>
    <div v-if="sliceLength" class="row m-0">
      <div class="connector" v-for="(platform, i) in connectors.slice(0, 8)" :key="i" :class="{
        active: findSelectedConnectors(platform) || addActiveClass,
      }" @click="addPlatform(platform, title)">
        <div class="row w-100 m-0" style="height: 100%">
          <div class="platform row" style="width: 90%; margin-left: 1px; align-items: center">
            <div class="image d-flex justify-center p-3">
              <img :src="getImage(platform.image)" :alt="platform.image" height="24px" width="24px"
                style="object-fit: contain;" />
            </div>
            <div class="text darkColorText font-medium ProximaNovaBold">
              {{ platform.name }}
            </div>
          </div>
          <div style="width: 10%; align-items: center" class="d-flex justify-center">
            <div class="action font-medium">
              <i class="fas fa-times" v-if="findSelectedConnectors(platform) || addActiveClass"></i>
              <i class="fas fa-plus" v-else></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="connectorModule" class="row m-0">
      <div class="connector" v-for="(platform, i) in connectors.slice(0, 8)" :key="i" :class="{
        active: findSelectedConnectors(platform) || addActiveClass,
      }" @click="addPlatform(platform, title)">
        <div class="row w-100 m-0" style="height: 100%">
          <div class="platform row" style="width: 90%; margin-left: 1px; align-items: center">
            <div class="image d-flex justify-center p-3">
              <img :src="getImage(platform.image)" :alt="platform.image" height="24px" width="24px"
                style="object-fit: contain;" />
            </div>
            <div class="text darkColorText font-medium ProximaNovaBold">
              {{ platform.name }}
            </div>
          </div>
          <div style="width: 10%; align-items: center" class="d-flex justify-center">
            <div class="action font-medium">
              <i class="fas fa-check" v-if="findSelectedConnectors(platform) || addActiveClass"></i>
              <i class="fas fa-plus" v-else></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="row m-0">
      <div class="connector" v-for="(platform, i) in connectors" :key="i" :class="{
        active: findSelectedConnectors(platform) || addActiveClass,
      }" @click="addPlatform(platform, title)">
        <div class="row w-100 m-0" style="height: 100%">
          <div class="platform row" style="width: 90%; margin-left: 1px; align-items: center">
            <div class="image d-flex justify-center p-3">
              <img :src="getImage(platform.image)" :alt="platform.image" height="24px" width="24px"
                style="object-fit: contain;" />
            </div>
            <div class="text darkColorText font-medium ProximaNovaBold">
              {{ platform.name }}
            </div>
          </div>
          <div style="width: 10%; align-items: center" class="d-flex justify-center">
            <div class="action font-medium ">
              <i class="fas fa-times" v-if="findSelectedConnectors(platform) || addActiveClass"></i>
              <i class="fas fa-plus" v-else></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    findSelectedConnectors() {
      return (data) => {
        if (
          this.selectedConnectorsList.find(
            (connectorObj) => connectorObj.role === data.role
          ) != undefined
        ) {
          return true;
        }
        return false;
      };
    },
  },
  props: {
    connectors: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: "Default",
    },
    selectedConnectorsList: {
      type: Array,
    },
    addActiveClass: {
      type: Boolean,
      default: false,
    },
    sliceLength: {
      type: Boolean,
      default: false,
    },
    connectorModule: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getImage(image) {
      try {
        return require("@/assets/connectors/" + image + ".svg");
      } catch (error) {
        if (error.code === "MODULE_NOT_FOUND") {
          try {
            return require("@/assets/connectors/" + image + ".jpg");
          } catch (error) {
            if (error.code === "MODULE_NOT_FOUND") {
              try {
                return require("@/assets/connectors/" + image + ".png");
              } catch {
                return image;
              }
            }
            return image;
          }
        }
        return image;
      }
    },
    addPlatform(platform, title) {
      this.$emit("addPlatform", { obj: platform, title: title });
    },
  },
};
</script>

<style scoped>
.connector .text {
  width: 83%;
}

.connector .image {
  width: 17%;
}

.connector .image {
  background-color: #fff;
}

.connector {
  background-color: #e9f0ff;
  height: 80px;
  width: 324px;
  cursor: pointer;
  font-family: ProximaNovaRegular;
  margin: 11px;
  border-radius: 5px;
}

.connector.active {
  background-color: #c8f0d1;
  font-family: ProximaNovaBold;
}

.connector:hover {
  background-color: #c8f0d1;
  font-family: ProximaNovaBold;
}
</style>
